import { Node, mergeAttributes } from '@tiptap/core';

// https://bhsn.atlassian.net/wiki/spaces/L/pages/153190412/HeaderFooter
type HeaderFooterType = 'header-even' | 'header-primary' | 'header-first' | 'footer-even' | 'footer-primary' | 'footer-first';

export const WordHeaderFooter = Node.create({
    name: 'headerFooter',
    group: 'headerFooter',
    content: 'block+',
    atom: true,
    defining: true,
    isolating: true,
    selectable: false,
    addAttributes() {
        return {
            headerFooterType: {
                default: 'header-primary' as HeaderFooterType,
                parseHTML: element => {
                    return (element.dataset.headerFooterType || 'header-primary') as HeaderFooterType;
                },
                renderHTML: attributes => {
                    return {
                        'data-header-footer-type': attributes.headerFooterType,
                    };
                },
            },
            isLinkedToPrevious: {
                default: false,
                parseHTML: element => {
                    return element.dataset.isLinkedToPrevious === 'true';
                },
                renderHTML: attributes => {
                    return {
                        'data-is-linked-to-previous': attributes.isLinkedToPrevious,
                    };
                },
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'div.word-header-footer',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes({ class: 'word-header-footer hidden' }, HTMLAttributes), 0];
    },
});
